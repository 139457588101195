/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

var apigClientFactory = {};
apigClientFactory.newClient = function (config) {
    var apigClient = { };
    if(config === undefined) {
        config = {
            accessKey: '',
            secretKey: '',
            sessionToken: '',
            region: 'ap-northeast-1',
            apiKey:  'ahrBMyyVEq74uAsE2f17K6vEDBulHLst60lYXKMN',
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json'
        };
    }
    if(config.accessKey === undefined) {
        config.accessKey = '';
    }
    if(config.secretKey === undefined) {
        config.secretKey = '';
    }
    if(config.apiKey === undefined) {
        config.apiKey = '';
    }
    if(config.sessionToken === undefined) {
        config.sessionToken = '';
    }
    if(config.region === undefined) {
        config.region = 'us-east-1';
    }
    //If defaultContentType is not defined then default to application/json
    if(config.defaultContentType === undefined) {
        config.defaultContentType = 'application/json';
    }
    //If defaultAcceptType is not defined then default to application/json
    if(config.defaultAcceptType === undefined) {
        config.defaultAcceptType = 'application/json';
    }

    
    // extract endpoint and path from url
    var invokeUrl = process.env.NODE_ENV==='development'?"https://retailing-data.net/api/dev/rema":"https://retailing-data.net/api/rema";
    var endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1];
    var pathComponent = invokeUrl.substring(endpoint.length);

    var sigV4ClientConfig = {
        accessKey: config.accessKey,
        secretKey: config.secretKey,
        sessionToken: config.sessionToken,
        serviceName: 'execute-api',
        region: config.region,
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var authType = 'NONE';
    if (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '') {
        authType = 'AWS_IAM';
    }

    var simpleHttpClientConfig = {
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var apiGatewayClient = apiGateway.core.apiGatewayClientFactory.newClient(simpleHttpClientConfig, sigV4ClientConfig);
    
    
    
    apigClient.activityGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var activityGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/activity').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var activityPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/activity').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var activityPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/activity').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var activityOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/activity').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityCalenderGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var activityCalenderGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/activity/calender').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityCalenderGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityCalenderOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var activityCalenderOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/activity/calender').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityCalenderOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityCalenderTriggerGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var activityCalenderTriggerGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/activity/calender/trigger').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityCalenderTriggerGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityCalenderTriggerOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var activityCalenderTriggerOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/activity/calender/trigger').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityCalenderTriggerOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityCalenderActivityIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['activity_id', 'Authorization'], ['body']);
        
        var activityCalenderActivityIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/activity/calender/{activity_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['activity_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityCalenderActivityIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityCalenderActivityIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['activity_id'], ['body']);
        
        var activityCalenderActivityIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/activity/calender/{activity_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['activity_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityCalenderActivityIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityActivityIdDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['activity_id', 'Authorization'], ['body']);
        
        var activityActivityIdDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/activity/{activity_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['activity_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityActivityIdDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.activityActivityIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['activity_id'], ['body']);
        
        var activityActivityIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/activity/{activity_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['activity_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(activityActivityIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceFbPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceFbOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbCreatePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceFbCreatePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbCreatePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbCreateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceFbCreateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbCreateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbDeleteOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceFbDeleteOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/delete').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbDeleteOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbDeleteIdDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'id'], ['body']);
        
        var audienceFbDeleteIdDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/delete/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbDeleteIdDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbDeleteIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['id'], ['body']);
        
        var audienceFbDeleteIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/delete/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbDeleteIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbListUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceFbListUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/list_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbListUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbListUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceFbListUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/list_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbListUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbSaveUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceFbSaveUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/save_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbSaveUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbSaveUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceFbSaveUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/save_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbSaveUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbShortenUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceFbShortenUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbShortenUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceFbShortenUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceFbShortenUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/fb/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceFbShortenUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGooglePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceGooglePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGooglePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceGoogleOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleCreatePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceGoogleCreatePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleCreatePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleCreateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceGoogleCreateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleCreateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleDeleteOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceGoogleDeleteOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/delete').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleDeleteOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleDeleteRuleIDDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var audienceGoogleDeleteRuleIDDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/delete/{ruleID}').expand(apiGateway.core.utils.parseParametersToObject(params, ['ruleID'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleDeleteRuleIDDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleDeleteRuleIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceGoogleDeleteRuleIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/delete/{ruleID}').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleDeleteRuleIDOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleListUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceGoogleListUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/list_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleListUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleListUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceGoogleListUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/list_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleListUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleSaveUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceGoogleSaveUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/save_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleSaveUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleSaveUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceGoogleSaveUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/save_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleSaveUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleShortenUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceGoogleShortenUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleShortenUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceGoogleShortenUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceGoogleShortenUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/google/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceGoogleShortenUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLinePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceLinePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLinePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceLineOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineCheckMaacGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceLineCheckMaacGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/check_maac').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineCheckMaacGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineCheckMaacOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceLineCheckMaacOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/check_maac').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineCheckMaacOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineCreatePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceLineCreatePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineCreatePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineCreateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceLineCreateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineCreateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineCreateMaacPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceLineCreateMaacPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/create_maac').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineCreateMaacPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineCreateMaacOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceLineCreateMaacOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/create_maac').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineCreateMaacOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineDeleteOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceLineDeleteOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/delete').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineDeleteOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineDeleteIdDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'id'], ['body']);
        
        var audienceLineDeleteIdDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/delete/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineDeleteIdDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineDeleteIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['id'], ['body']);
        
        var audienceLineDeleteIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/delete/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineDeleteIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineListUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceLineListUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/list_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineListUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineListUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceLineListUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/list_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineListUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineSaveUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceLineSaveUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/save_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineSaveUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineSaveUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceLineSaveUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/save_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineSaveUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineShortenUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var audienceLineShortenUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineShortenUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceLineShortenUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceLineShortenUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/line/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceLineShortenUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceOfficialNameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['type', 'Authorization'], ['body']);
        
        var audienceOfficialNameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/official_name').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['type', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceOfficialNameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.audienceOfficialNameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var audienceOfficialNameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/audience/official_name').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(audienceOfficialNameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelAilabelGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'Authorization'], ['body']);
        
        var customlabelAilabelGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/ailabel').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelAilabelGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelAilabelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelAilabelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/ailabel').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelAilabelOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelAilabelsGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelAilabelsGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/ailabels').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelAilabelsGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelAilabelsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelAilabelsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/ailabels').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelAilabelsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelChannelPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelChannelPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/channel').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelChannelPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelChannelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelChannelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/channel').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelChannelOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelCreatePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelCreatePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelCreatePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelCreateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelCreateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelCreateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelDeleteDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelDeleteDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/delete').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelDeleteDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelDeleteOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelDeleteOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/delete').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelDeleteOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelFilterListGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['extra_data', 'Authorization', 'tag_param'], ['body']);
        
        var customlabelFilterListGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/filter_list').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['extra_data', 'tag_param']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelFilterListGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelFilterListOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelFilterListOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/filter_list').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelFilterListOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelFilterParamGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelFilterParamGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/filter_param').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelFilterParamGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelFilterParamOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelFilterParamOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/filter_param').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelFilterParamOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelFilterStructureGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelFilterStructureGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/filter_structure').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelFilterStructureGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelFilterStructureOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelFilterStructureOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/filter_structure').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelFilterStructureOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelListGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelListGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/list').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelListGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelListOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelListOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/list').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelListOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelMarketingOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingAllGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelMarketingAllGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/all').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingAllGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingAllOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelMarketingAllOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/all').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingAllOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingCreatePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelMarketingCreatePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingCreatePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingCreateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelMarketingCreateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingCreateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingDeliveryCountPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelMarketingDeliveryCountPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/delivery_count').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingDeliveryCountPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingDeliveryCountOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelMarketingDeliveryCountOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/delivery_count').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingDeliveryCountOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingRatePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelMarketingRatePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingRatePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelMarketingRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingLabelIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'Authorization'], ['body']);
        
        var customlabelMarketingLabelIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/{label_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['label_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingLabelIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelMarketingLabelIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id'], ['body']);
        
        var customlabelMarketingLabelIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/marketing/{label_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['label_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelMarketingLabelIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelOverallGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelOverallGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/overall').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelOverallGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelOverallOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelOverallOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/overall').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelOverallOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelRatePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelRatePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelRatePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelRateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelRateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/rate').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelRateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelStructrueGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var customlabelStructrueGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/structrue').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelStructrueGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelStructrueOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var customlabelStructrueOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/structrue').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelStructrueOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelLabelIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'Authorization'], ['body']);
        
        var customlabelLabelIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/{label_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['label_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelLabelIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.customlabelLabelIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id'], ['body']);
        
        var customlabelLabelIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/customlabel/{label_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['label_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(customlabelLabelIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.datauploadFileUploadPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'Content-Type', 'Accept'], ['body']);
        
        var datauploadFileUploadPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dataupload/file_upload').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', 'Content-Type', 'Accept']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(datauploadFileUploadPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.datauploadFileUploadOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var datauploadFileUploadOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dataupload/file_upload').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(datauploadFileUploadOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.datauploadFileUploadCouponPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'Content-Type', 'Accept'], ['body']);
        
        var datauploadFileUploadCouponPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dataupload/file_upload/coupon').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', 'Content-Type', 'Accept']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(datauploadFileUploadCouponPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.datauploadFileUploadCouponOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var datauploadFileUploadCouponOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dataupload/file_upload/coupon').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(datauploadFileUploadCouponOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.datauploadLabelUploadPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'Content-Type', 'Accept'], ['body']);
        
        var datauploadLabelUploadPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dataupload/label_upload').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', 'Content-Type', 'Accept']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(datauploadLabelUploadPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.datauploadLabelUploadOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var datauploadLabelUploadOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dataupload/label_upload').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(datauploadLabelUploadOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.imagesOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var imagesOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/images').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(imagesOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.imagesInfoGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var imagesInfoGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/images/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(imagesInfoGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.imagesInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var imagesInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/images/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(imagesInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.imagesInfoDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var imagesInfoDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/images/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(imagesInfoDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.imagesInfoPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var imagesInfoPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/images/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(imagesInfoPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.imagesInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var imagesInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/images/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(imagesInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.imagesUploadurlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'mimetype'], ['body']);
        
        var imagesUploadurlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/images/uploadurl').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['mimetype']),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(imagesUploadurlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.imagesUploadurlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var imagesUploadurlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/images/uploadurl').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(imagesUploadurlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementCountCustomizedGroupOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementCountCustomizedGroupOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/count_customized_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementCountCustomizedGroupOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementCountCustomizedGroupLabelIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id'], ['body']);
        
        var labelManagementCountCustomizedGroupLabelIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/count_customized_group/{label_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['label_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementCountCustomizedGroupLabelIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementCountCustomizedGroupLabelIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementCountCustomizedGroupLabelIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/count_customized_group/{label_id}').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementCountCustomizedGroupLabelIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementCountMembersPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementCountMembersPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/count_members').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementCountMembersPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementCountMembersOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementCountMembersOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/count_members').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementCountMembersOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementCreateFromCustomizedGroupPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementCreateFromCustomizedGroupPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/create_from_customized_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementCreateFromCustomizedGroupPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementCreateFromCustomizedGroupOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementCreateFromCustomizedGroupOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/create_from_customized_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementCreateFromCustomizedGroupOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementCreateFromUploadPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'Content-Type', 'Accept'], ['body']);
        
        var labelManagementCreateFromUploadPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/create_from_upload').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', 'Content-Type', 'Accept']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementCreateFromUploadPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementCreateFromUploadOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementCreateFromUploadOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/create_from_upload').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementCreateFromUploadOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementDeleteLabelGroupPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementDeleteLabelGroupPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/delete_label_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementDeleteLabelGroupPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementDeleteLabelGroupOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementDeleteLabelGroupOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/delete_label_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementDeleteLabelGroupOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementDeleteMembersPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementDeleteMembersPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/delete_members').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementDeleteMembersPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementDeleteMembersOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementDeleteMembersOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/delete_members').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementDeleteMembersOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementLabelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['id'], ['body']);
        
        var labelManagementLabelIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelIdDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['id'], ['body']);
        
        var labelManagementLabelIdDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelIdDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementLabelIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelGroupGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementLabelGroupGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelGroupGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelGroupPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementLabelGroupPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelGroupPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelGroupOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementLabelGroupOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelGroupOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelGroupGroupIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['group_id'], ['body']);
        
        var labelManagementLabelGroupGroupIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label_group/{group_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['group_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelGroupGroupIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelGroupGroupIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementLabelGroupGroupIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label_group/{group_id}').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelGroupGroupIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelGroupsCustomizedGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementLabelGroupsCustomizedGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label_groups_customized').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelGroupsCustomizedGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementLabelGroupsCustomizedOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementLabelGroupsCustomizedOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/label_groups_customized').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementLabelGroupsCustomizedOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementListCustomizedGroupGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementListCustomizedGroupGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/list_customized_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementListCustomizedGroupGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementListCustomizedGroupOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementListCustomizedGroupOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/list_customized_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementListCustomizedGroupOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementOutputLabelGroupPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementOutputLabelGroupPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/output_label_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementOutputLabelGroupPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementOutputLabelGroupOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementOutputLabelGroupOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/output_label_group').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementOutputLabelGroupOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementOutputMembersPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var labelManagementOutputMembersPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/output_members').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementOutputMembersPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.labelManagementOutputMembersOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var labelManagementOutputMembersOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/label_management/output_members').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(labelManagementOutputMembersOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusChangePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusChangePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/change').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusChangePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusChangeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusChangeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/change').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusChangeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusExportPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusExportPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/export').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusExportPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusExportOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusExportOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/export').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusExportOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusExportBillGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['date', 'Authorization'], ['body']);
        
        var memberStatusExportBillGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/export_bill').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusExportBillGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusExportBillOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusExportBillOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/export_bill').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusExportBillOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusGetEdmSmsGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['start_date', 'end_date', 'Authorization'], ['body']);
        
        var memberStatusGetEdmSmsGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/get_edm_sms').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusGetEdmSmsGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusGetEdmSmsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusGetEdmSmsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/get_edm_sms').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusGetEdmSmsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusOpenClickPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusOpenClickPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/open_click').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusOpenClickPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusOpenClickOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusOpenClickOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/open_click').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusOpenClickOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusQuotaGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusQuotaGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/quota').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusQuotaGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusQuotaPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusQuotaPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/quota').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusQuotaPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusQuotaOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusQuotaOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/quota').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusQuotaOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusRemainingEmailStoreValueGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusRemainingEmailStoreValueGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/remaining_email_store_value').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusRemainingEmailStoreValueGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusRemainingEmailStoreValueOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusRemainingEmailStoreValueOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/remaining_email_store_value').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusRemainingEmailStoreValueOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusRemainingStoredValueGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusRemainingStoredValueGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/remaining_stored_value').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusRemainingStoredValueGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusRemainingStoredValueOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusRemainingStoredValueOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/remaining_stored_value').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusRemainingStoredValueOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusSearchPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusSearchPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/search').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusSearchPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusSearchOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusSearchOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/search').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusSearchOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusWarnGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusWarnGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/warn').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusWarnGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusWarnPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var memberStatusWarnPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/warn').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusWarnPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.memberStatusWarnOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var memberStatusWarnOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/member_status/warn').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(memberStatusWarnOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelAgeGenderPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['year_month', 'Authorization'], ['body']);
        
        var multipleLabelAgeGenderPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/age_gender').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['year_month', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelAgeGenderPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelAgeGenderOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelAgeGenderOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/age_gender').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelAgeGenderOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelChannelsPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['year_month', 'Authorization'], ['body']);
        
        var multipleLabelChannelsPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/channels').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['year_month', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelChannelsPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelChannelsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelChannelsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/channels').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelChannelsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelCheckPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var multipleLabelCheckPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/check').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelCheckPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelCheckOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelCheckOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/check').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelCheckOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelCountInfosGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'start_date', 'end_date', 'Authorization'], ['body']);
        
        var multipleLabelCountInfosGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/count_infos').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', 'start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelCountInfosGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelCountInfosOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelCountInfosOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/count_infos').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelCountInfosOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelCountRegionGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'start_date', 'end_date', 'Authorization'], ['body']);
        
        var multipleLabelCountRegionGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/count_region').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', 'start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelCountRegionGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelCountRegionOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelCountRegionOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/count_region').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelCountRegionOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelCountRfmlabelGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'start_date', 'end_date', 'Authorization'], ['body']);
        
        var multipleLabelCountRfmlabelGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/count_rfmlabel').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', 'start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelCountRfmlabelGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelCountRfmlabelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelCountRfmlabelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/count_rfmlabel').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelCountRfmlabelOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelExportPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var multipleLabelExportPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/export').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelExportPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelExportOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelExportOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/export').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelExportOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelExportLabelTagGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'start_date', 'end_date', 'label_name', 'Authorization'], ['body']);
        
        var multipleLabelExportLabelTagGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/export_label_tag').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', 'start_date', 'end_date', 'label_name', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelExportLabelTagGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelExportLabelTagOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelExportLabelTagOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/export_label_tag').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelExportLabelTagOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelHeadCountPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['year_month', 'Authorization'], ['body']);
        
        var multipleLabelHeadCountPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/head_count').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['year_month', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelHeadCountPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelHeadCountOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelHeadCountOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/head_count').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelHeadCountOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelInfoGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'Authorization'], ['body']);
        
        var multipleLabelInfoGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelInfoGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelInteractionPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['year_month', 'Authorization'], ['body']);
        
        var multipleLabelInteractionPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/interaction').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['year_month', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelInteractionPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelInteractionOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelInteractionOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/interaction').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelInteractionOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelLabelnamePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var multipleLabelLabelnamePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/labelname').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelLabelnamePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelLabelnameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelLabelnameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/labelname').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelLabelnameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelMarketingInteractionGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'start_date', 'end_date', 'Authorization'], ['body']);
        
        var multipleLabelMarketingInteractionGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/marketing_interaction').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', 'start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelMarketingInteractionGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelMarketingInteractionOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelMarketingInteractionOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/marketing_interaction').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelMarketingInteractionOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelOldCustPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['year_month', 'Authorization'], ['body']);
        
        var multipleLabelOldCustPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/old_cust').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['year_month', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelOldCustPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelOldCustOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelOldCustOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/old_cust').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelOldCustOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelOldnewPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['year_month', 'Authorization'], ['body']);
        
        var multipleLabelOldnewPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/oldnew').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['year_month', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelOldnewPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelOldnewOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelOldnewOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/oldnew').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelOldnewOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelOrderConsumeGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'start_date', 'end_date', 'Authorization'], ['body']);
        
        var multipleLabelOrderConsumeGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/order_consume').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', 'start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelOrderConsumeGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelOrderConsumeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelOrderConsumeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/order_consume').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelOrderConsumeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelOtherLabelsPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['year_month', 'Authorization'], ['body']);
        
        var multipleLabelOtherLabelsPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/other_labels').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['year_month', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelOtherLabelsPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelOtherLabelsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelOtherLabelsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/other_labels').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelOtherLabelsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelProdBuyPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['year_month', 'Authorization'], ['body']);
        
        var multipleLabelProdBuyPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/prod_buy').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['year_month', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelProdBuyPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelProdBuyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelProdBuyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/prod_buy').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelProdBuyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelPurchasePerformanceGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'start_date', 'end_date', 'Authorization'], ['body']);
        
        var multipleLabelPurchasePerformanceGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/purchase_performance').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', 'start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelPurchasePerformanceGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelPurchasePerformanceOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelPurchasePerformanceOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/purchase_performance').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelPurchasePerformanceOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelRecPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var multipleLabelRecPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/rec').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelRecPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelRecOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelRecOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/rec').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelRecOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelSpendingPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['year_month', 'Authorization'], ['body']);
        
        var multipleLabelSpendingPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/spending').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['year_month', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelSpendingPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelSpendingOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelSpendingOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/spending').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelSpendingOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelTimeGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'Authorization'], ['body']);
        
        var multipleLabelTimeGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelTimeGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelTimePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var multipleLabelTimePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelTimePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelTimeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelTimeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelTimeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelTopItemsGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'start_date', 'end_date', 'Authorization'], ['body']);
        
        var multipleLabelTopItemsGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/top_items').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', 'start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelTopItemsGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelTopItemsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelTopItemsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/top_items').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelTopItemsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelTotalPeopleGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'start_date', 'end_date', 'Authorization'], ['body']);
        
        var multipleLabelTotalPeopleGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/total_people').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', 'start_date', 'end_date', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelTotalPeopleGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.multipleLabelTotalPeopleOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var multipleLabelTotalPeopleOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/multiple_label/total_people').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(multipleLabelTotalPeopleOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.reportOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var reportOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/report').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(reportOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.reportOnlineReportPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var reportOnlineReportPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/report/online_report').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(reportOnlineReportPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.reportOnlineReportOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var reportOnlineReportOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/report/online_report').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(reportOnlineReportOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.reportProductReportPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var reportProductReportPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/report/product_report').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(reportProductReportPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.reportProductReportOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var reportProductReportOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/report/product_report').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(reportProductReportOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.reportSaleReportPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var reportSaleReportPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/report/sale_report').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(reportSaleReportPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.reportSaleReportOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var reportSaleReportOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/report/sale_report').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(reportSaleReportOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scheduleGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var scheduleGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scheduleGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.schedulePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var schedulePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(schedulePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scheduleOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var scheduleOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scheduleOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scheduleUpdateHasEventOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var scheduleUpdateHasEventOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule/update-has-event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scheduleUpdateHasEventOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scheduleUpdateHasEventScriptIdPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['state', 'script_id', 'Authorization'], ['body']);
        
        var scheduleUpdateHasEventScriptIdPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule/update-has-event/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['state', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scheduleUpdateHasEventScriptIdPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scheduleUpdateHasEventScriptIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id'], ['body']);
        
        var scheduleUpdateHasEventScriptIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule/update-has-event/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scheduleUpdateHasEventScriptIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scheduleRuleIDGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['ruleID'], ['body']);
        
        var scheduleRuleIDGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule/{ruleID}').expand(apiGateway.core.utils.parseParametersToObject(params, ['ruleID'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scheduleRuleIDGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scheduleRuleIDDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var scheduleRuleIDDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule/{ruleID}').expand(apiGateway.core.utils.parseParametersToObject(params, ['ruleID'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scheduleRuleIDDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scheduleRuleIDPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'ruleID'], ['body']);
        
        var scheduleRuleIDPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule/{ruleID}').expand(apiGateway.core.utils.parseParametersToObject(params, ['ruleID'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scheduleRuleIDPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scheduleRuleIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['ruleID'], ['body']);
        
        var scheduleRuleIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/schedule/{ruleID}').expand(apiGateway.core.utils.parseParametersToObject(params, ['ruleID'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scheduleRuleIDOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var scriptPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/script').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var scriptOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptScriptIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var scriptScriptIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/script/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptScriptIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptScriptIdDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var scriptScriptIdDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/script/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptScriptIdDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptScriptIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id'], ['body']);
        
        var scriptScriptIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptScriptIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptOverallGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var scriptOverallGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/script_overall').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptOverallGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptOverallOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var scriptOverallOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script_overall').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptOverallOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var scriptTempOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempCachePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var scriptTempCachePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/cache').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempCachePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempCacheDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var scriptTempCacheDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/cache').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempCacheDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempCachePatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var scriptTempCachePatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/cache').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempCachePatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempCacheOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var scriptTempCacheOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/cache').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempCacheOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempCacheKeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key', 'Authorization'], ['body']);
        
        var scriptTempCacheKeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/cache/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempCacheKeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempCacheKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key'], ['body']);
        
        var scriptTempCacheKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/cache/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempCacheKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempSchedulePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var scriptTempSchedulePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/schedule').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempSchedulePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempSchedulePatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var scriptTempSchedulePatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/schedule').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempSchedulePatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempScheduleOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var scriptTempScheduleOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/schedule').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempScheduleOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempScheduleIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'id'], ['body']);
        
        var scriptTempScheduleIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/schedule/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempScheduleIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempScheduleIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['id'], ['body']);
        
        var scriptTempScheduleIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/schedule/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempScheduleIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempTempPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var scriptTempTempPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/temp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempTempPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempTempOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var scriptTempTempOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/temp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempTempOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempTempIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'id'], ['body']);
        
        var scriptTempTempIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/temp/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempTempIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.scriptTempTempIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['id'], ['body']);
        
        var scriptTempTempIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/script_temp/temp/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(scriptTempTempIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsScheduleGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScheduleOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleCreatePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsScheduleCreatePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleCreatePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleCreateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScheduleCreateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/create').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleCreateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleShortenUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsScheduleShortenUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleShortenUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleShortenUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScheduleShortenUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleShortenUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleUpdateHasEventOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScheduleUpdateHasEventOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/update-has-event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleUpdateHasEventOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleUpdateHasEventIdPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'id'], ['body']);
        
        var smsScheduleUpdateHasEventIdPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/update-has-event/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleUpdateHasEventIdPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleUpdateHasEventIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['id'], ['body']);
        
        var smsScheduleUpdateHasEventIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/update-has-event/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleUpdateHasEventIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleScheduleNameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['scheduleName', 'Authorization'], ['body']);
        
        var smsScheduleScheduleNameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/{scheduleName}').expand(apiGateway.core.utils.parseParametersToObject(params, ['scheduleName', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleScheduleNameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleScheduleNameDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['scheduleName', 'Authorization'], ['body']);
        
        var smsScheduleScheduleNameDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/{scheduleName}').expand(apiGateway.core.utils.parseParametersToObject(params, ['scheduleName', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleScheduleNameDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleScheduleNamePatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['scheduleName', 'Authorization'], ['body']);
        
        var smsScheduleScheduleNamePatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/{scheduleName}').expand(apiGateway.core.utils.parseParametersToObject(params, ['scheduleName', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleScheduleNamePatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScheduleScheduleNameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['scheduleName'], ['body']);
        
        var smsScheduleScheduleNameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/schedule/{scheduleName}').expand(apiGateway.core.utils.parseParametersToObject(params, ['scheduleName'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScheduleScheduleNameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsSendPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsSendPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/send').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsSendPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsSendOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsSendOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms/send').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsSendOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsScriptPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_script').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsScriptOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_script').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'id'], ['body']);
        
        var smsScriptIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_script/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptIdDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization', 'id'], ['body']);
        
        var smsScriptIdDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_script/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization', ]),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptIdDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsScriptIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['id'], ['body']);
        
        var smsScriptIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_script/{id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsScriptIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsCacheOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateSmsCacheOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_cache').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsCacheOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsCacheInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsCacheInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_cache/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsCacheInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsCacheInfoDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsCacheInfoDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_cache/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsCacheInfoDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsCacheInfoPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsCacheInfoPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_cache/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsCacheInfoPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsCacheInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateSmsCacheInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_cache/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsCacheInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsCacheKeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key', 'Authorization'], ['body']);
        
        var smsTemplateSmsCacheKeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_cache/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsCacheKeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsCacheKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key'], ['body']);
        
        var smsTemplateSmsCacheKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_cache/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsCacheKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsScheduleOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateSmsScheduleOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_schedule').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsScheduleOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsScheduleInfoGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsScheduleInfoGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_schedule/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsScheduleInfoGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsScheduleInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsScheduleInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_schedule/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsScheduleInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsScheduleInfoDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateSmsScheduleInfoDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_schedule/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsScheduleInfoDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsScheduleInfoPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsScheduleInfoPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_schedule/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsScheduleInfoPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsScheduleInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateSmsScheduleInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_schedule/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsScheduleInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsScheduleKeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key', 'Authorization'], ['body']);
        
        var smsTemplateSmsScheduleKeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_schedule/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsScheduleKeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsScheduleKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key'], ['body']);
        
        var smsTemplateSmsScheduleKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_schedule/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsScheduleKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTempOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateSmsTempOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_temp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTempOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTempInfoGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsTempInfoGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_temp/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTempInfoGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTempInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsTempInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_temp/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTempInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTempInfoDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsTempInfoDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_temp/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTempInfoDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTempInfoPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsTempInfoPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_temp/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTempInfoPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTempInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateSmsTempInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_temp/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTempInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTempKeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key', 'Authorization'], ['body']);
        
        var smsTemplateSmsTempKeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_temp/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTempKeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTempKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key'], ['body']);
        
        var smsTemplateSmsTempKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_temp/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTempKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTransitOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateSmsTransitOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_transit').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTransitOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTransitInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsTransitInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_transit/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTransitInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTransitInfoDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsTransitInfoDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_transit/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTransitInfoDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTransitInfoPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var smsTemplateSmsTransitInfoPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_transit/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTransitInfoPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTransitInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var smsTemplateSmsTransitInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_transit/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTransitInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTransitKeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key', 'Authorization'], ['body']);
        
        var smsTemplateSmsTransitKeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_transit/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTransitKeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.smsTemplateSmsTransitKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key'], ['body']);
        
        var smsTemplateSmsTransitKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/sms_template/sms_transit/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(smsTemplateSmsTransitKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templateOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templateOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/template').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templateOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templateShortenUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templateShortenUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/template/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templateShortenUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templateShortenUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templateShortenUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/template/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templateShortenUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templatev2OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2OptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2CacheOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templatev2CacheOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/cache').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2CacheOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2CacheInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2CacheInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/cache/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2CacheInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2CacheInfoDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2CacheInfoDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/cache/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2CacheInfoDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2CacheInfoPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2CacheInfoPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/cache/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2CacheInfoPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2CacheInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templatev2CacheInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/cache/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2CacheInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2CacheKeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key', 'Authorization'], ['body']);
        
        var templatev2CacheKeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/cache/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2CacheKeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2CacheKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key'], ['body']);
        
        var templatev2CacheKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/cache/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2CacheKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2HtmlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templatev2HtmlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/html').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2HtmlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2HtmlInfoGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2HtmlInfoGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/html/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2HtmlInfoGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2HtmlInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2HtmlInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/html/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2HtmlInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2HtmlInfoDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2HtmlInfoDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/html/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2HtmlInfoDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2HtmlInfoPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2HtmlInfoPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/html/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2HtmlInfoPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2HtmlInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templatev2HtmlInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/html/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2HtmlInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2HtmlKeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key', 'Authorization'], ['body']);
        
        var templatev2HtmlKeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/html/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2HtmlKeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2HtmlKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key'], ['body']);
        
        var templatev2HtmlKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/html/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2HtmlKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TempOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templatev2TempOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/temp').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TempOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TempInfoGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2TempInfoGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/temp/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TempInfoGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TempInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2TempInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/temp/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TempInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TempInfoDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2TempInfoDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/temp/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TempInfoDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TempInfoPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2TempInfoPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/temp/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TempInfoPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TempKeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key', 'Authorization'], ['body']);
        
        var templatev2TempKeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/temp/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TempKeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TempKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key'], ['body']);
        
        var templatev2TempKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/temp/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TempKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TransitOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templatev2TransitOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/transit').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TransitOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TransitInfoPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2TransitInfoPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/transit/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TransitInfoPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TransitInfoDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2TransitInfoDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/transit/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TransitInfoDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TransitInfoPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2TransitInfoPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/transit/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TransitInfoPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TransitInfoOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templatev2TransitInfoOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/transit/info').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TransitInfoOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TransitKeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key', 'Authorization'], ['body']);
        
        var templatev2TransitKeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/transit/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TransitKeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2TransitKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['key'], ['body']);
        
        var templatev2TransitKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/transit/{key}').expand(apiGateway.core.utils.parseParametersToObject(params, ['key'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2TransitKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2UnsubscribeUrlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2UnsubscribeUrlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/unsubscribe_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2UnsubscribeUrlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2UnsubscribeUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var templatev2UnsubscribeUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/unsubscribe_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2UnsubscribeUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.templatev2UnsubscribeUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var templatev2UnsubscribeUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/templatev2/unsubscribe_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(templatev2UnsubscribeUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.testLabelGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var testLabelGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/test_label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(testLabelGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.testLabelPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var testLabelPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/test_label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(testLabelPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.testLabelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var testLabelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/test_label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(testLabelOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.testLabelSendEmailPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var testLabelSendEmailPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/test_label/send_email').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(testLabelSendEmailPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.testLabelSendEmailOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var testLabelSendEmailOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/test_label/send_email').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(testLabelSendEmailOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerEventOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventCartPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerEventCartPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/cart').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventCartPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventCartOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerEventCartOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/cart').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventCartOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventCartCdbIdPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['cdb_id'], ['body']);
        
        var triggerEventCartCdbIdPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/cart/{cdb_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['cdb_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventCartCdbIdPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventCartCdbIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['cdb_id'], ['body']);
        
        var triggerEventCartCdbIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/cart/{cdb_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['cdb_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventCartCdbIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventPurchasePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerEventPurchasePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventPurchasePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventPurchaseOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerEventPurchaseOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/purchase').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventPurchaseOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventPurchaseCdbIdPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['cdb_id'], ['body']);
        
        var triggerEventPurchaseCdbIdPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/purchase/{cdb_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['cdb_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventPurchaseCdbIdPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventPurchaseCdbIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['cdb_id'], ['body']);
        
        var triggerEventPurchaseCdbIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/purchase/{cdb_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['cdb_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventPurchaseCdbIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventPurchaseMarketingOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerEventPurchaseMarketingOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/purchase_marketing').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventPurchaseMarketingOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventPurchaseMarketingCdbIdPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['cdb_id'], ['body']);
        
        var triggerEventPurchaseMarketingCdbIdPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/purchase_marketing/{cdb_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['cdb_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventPurchaseMarketingCdbIdPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventPurchaseMarketingCdbIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['cdb_id'], ['body']);
        
        var triggerEventPurchaseMarketingCdbIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/purchase_marketing/{cdb_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['cdb_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventPurchaseMarketingCdbIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventRegisterPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerEventRegisterPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/register').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventRegisterPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventRegisterOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerEventRegisterOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/register').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventRegisterOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventRegisterCdbIdPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['cdb_id'], ['body']);
        
        var triggerEventRegisterCdbIdPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/register/{cdb_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['cdb_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventRegisterCdbIdPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerEventRegisterCdbIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['cdb_id'], ['body']);
        
        var triggerEventRegisterCdbIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_event/register/{cdb_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['cdb_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerEventRegisterCdbIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var triggerScriptPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptCreateLabelPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var triggerScriptCreateLabelPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/create_label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptCreateLabelPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptCreateLabelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptCreateLabelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/create_label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptCreateLabelOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptGetExecTimePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var triggerScriptGetExecTimePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/get_exec_time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptGetExecTimePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptGetExecTimeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptGetExecTimeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/get_exec_time').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptGetExecTimeOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptScriptIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var triggerScriptScriptIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/script_id').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptScriptIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptScriptIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptScriptIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/script_id').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptScriptIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptShortenUrlPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var triggerScriptShortenUrlPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptShortenUrlPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptShortenUrlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptShortenUrlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/shorten_url').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptShortenUrlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptUpdateHasEventOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var triggerScriptUpdateHasEventOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/update-has-event').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptUpdateHasEventOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptUpdateHasEventScriptIdPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var triggerScriptUpdateHasEventScriptIdPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/update-has-event/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptUpdateHasEventScriptIdPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptUpdateHasEventScriptIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id'], ['body']);
        
        var triggerScriptUpdateHasEventScriptIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/update-has-event/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptUpdateHasEventScriptIdOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptScriptIdGet_1 = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var triggerScriptScriptIdGet_1Request = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptScriptIdGet_1Request, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptScriptIdDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var triggerScriptScriptIdDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptScriptIdDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptScriptIdPatch = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id', 'Authorization'], ['body']);
        
        var triggerScriptScriptIdPatchRequest = {
            verb: 'patch'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id', ])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptScriptIdPatchRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.triggerScriptScriptIdOptions_2 = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['script_id'], ['body']);
        
        var triggerScriptScriptIdOptions_2Request = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/trigger_script/{script_id}').expand(apiGateway.core.utils.parseParametersToObject(params, ['script_id'])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(triggerScriptScriptIdOptions_2Request, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.unlayerHtmlGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var unlayerHtmlGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/unlayer_html').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(unlayerHtmlGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.unlayerHtmlOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var unlayerHtmlOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/unlayer_html').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(unlayerHtmlOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.uploadLabelGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var uploadLabelGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/upload_label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(uploadLabelGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.uploadLabelPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var uploadLabelPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/upload_label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(uploadLabelPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.uploadLabelDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'Authorization'], ['body']);
        
        var uploadLabelDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/upload_label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(uploadLabelDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.uploadLabelOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var uploadLabelOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/upload_label').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(uploadLabelOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.uploadLabelExportGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['label_id', 'Authorization'], ['body']);
        
        var uploadLabelExportGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/upload_label/export').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, ['label_id', ]),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(uploadLabelExportGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.uploadLabelExportOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var uploadLabelExportOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/upload_label/export').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(uploadLabelExportOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/user').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userEmaillistGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var userEmaillistGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/user/emaillist').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userEmaillistGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userEmaillistOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userEmaillistOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/user/emaillist').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userEmaillistOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userForgotpasswordPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userForgotpasswordPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/user/forgotpassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userForgotpasswordPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userForgotpasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userForgotpasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/user/forgotpassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userForgotpasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userNotificationPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userNotificationPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/user/notification').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userNotificationPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userNotificationOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userNotificationOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/user/notification').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userNotificationOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userResetpasswordPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userResetpasswordPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/user/resetpassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userResetpasswordPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userResetpasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userResetpasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/user/resetpassword').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userResetpasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userSigninPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userSigninPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/user/signin').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userSigninPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userSigninOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userSigninOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/user/signin').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userSigninOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userSignupPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userSignupPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/user/signup').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userSignupPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userSignupOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userSignupOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/user/signup').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userSignupOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userSmslistGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, ['Authorization'], ['body']);
        
        var userSmslistGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/user/smslist').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, ['Authorization']),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userSmslistGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userSmslistOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userSmslistOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/user/smslist').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userSmslistOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userVerifytokenPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userVerifytokenPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/user/verifytoken').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userVerifytokenPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.userVerifytokenOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
        
        var userVerifytokenOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/user/verifytoken').expand(apiGateway.core.utils.parseParametersToObject(params, [])),
            headers: apiGateway.core.utils.parseParametersToObject(params, []),
            queryParams: apiGateway.core.utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(userVerifytokenOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    return apigClient;
};
 export var allapi2 = apigClientFactory.newClient();