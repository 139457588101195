/* 存放左側導覽列的資料 */

//icon
import AIDataAnalyze from "../assets/nav-ai-data-analyze.svg";
import AIDataAnalyzeGray from "../assets/nav-ai-data-analyze-grey.svg";
import iconPlatMember from "../assets/platMemberBlue.svg";
import iconPlatMemberGray from "../assets/platMemberGray.svg";
import iconOperationalDisplayGray from "../assets/trendGray.png";
import iconOperationalDisplay from "../assets/trend.png";
import iconWebsiteBehaviorGray from "../assets/website-anal-grey.svg";
import iconWebsiteBehavior from "../assets/website-anal-blue.svg";
import iconCustomerBuyGray from "../assets/analyzeGray.png";
import iconCustomerBuy from "../assets/analyze.png";
import iconNew360Gary from "../assets/New360Gray.svg";
import iconNew360 from "../assets/New360White.svg";
import iconCustomerAnalysisGray from "../assets/Customer-analysis-gray.svg";
import iconCustomerAnalysis from "../assets/Customer-analysis.svg";
import iconBuildingCaseGray from "../assets/Building-case-gray.svg";
import iconBuildingCase from "../assets/Building-case.svg";
import iconCustomerLabelGray from "../assets/customTagGroupGray.svg";
import iconCustomerLabel from "../assets/customTagGroupBlue.svg";
import iconRema from "../assets/rema.svg";
import iconSalePerformanceGray from "../assets/effectGray.svg";
import iconSalePerformance from "../assets/effectBlue.svg";

const navOverview = [
  {
    name: "營運表現",
    img: iconOperationalDisplayGray,
    imgFocus: iconOperationalDisplay,
    path: "",
    lv: "OperationalPerformance", //判斷是否顯示這個按鈕，與this.lv對照的參數
    clicked: "behavior", //如果可以展開時才需要此參數。判斷是否被點開
    children: [
      {
        name: "營運總覽",
        path: "/OperationalOverview",
        lv: "OperationalOverview", //判斷是否顯示這個按鈕，與this.lv對照的參數
        clicked: "",
        children: [],
      },
      {
        name: "指標同比",
        path: "/IndicatorCompare",
        lv: "IndicatorCompare",
        clicked: "",
        children: [],
      },
      {
        name: "會員銷售",
        path: "/MembershipSales",
        lv: "MembershipSales",
        clicked: "",
        children: [],
      },
      {
        name: "商品銷售",
        path: "",
        lv: "CommoditySales",
        clicked: "retailGoods",
        children: [
          {
            name: "銷售表現",
            path: "/CommoditySales",
            lv: "CommoditySales",
            clicked: "",
            children: [],
          },
          {
            name: "商品進階分析",
            path: "/CommodityAdvanceAnalysis",
            lv: "CommodityAdvanceAnalysis",
            clicked: "",
            children: [],
          },
        ],
      },
      {
        name: "門市通路銷售",
        path: "/StoreChannelSales",
        lv: "StoreChannelSales",
        clicked: "",
        children: [],
      },
    ],
  },
  {
    name: "網站行為",
    img: iconWebsiteBehaviorGray,
    imgFocus: iconWebsiteBehavior,
    path: "",
    lv: "WebsiteBehavior",
    clicked: "Website",
    children: [
      {
        name: "網站行為分析",
        path: "/WebsiteAnalysis",
        lv: "",
        clicked: "",
        children: [],
      },
      {
        name: "網站進階分析",
        path: "/WebsiteAdvance",
        lv: "",
        clicked: "",
        children: [],
      },
    ],
  },
  {
    name: "顧客購物行為",
    img: iconCustomerBuyGray,
    imgFocus: iconCustomerBuy,
    path: "",
    lv: "CustomerShopbehavior",
    clicked: "labelGroup",
    children: [
      {
        name: "整體顧客行為",
        path: "/ValueGrouping",
        lv: "ValueGrouping",
        clicked: "",
        children: [],
      },
      {
        name: "線上購物行為",
        path: "/CustomLabel",
        lv: "CustomLabel",
        clicked: "",
        children: [],
      },
      {
        name: "線下購物行為",
        path: "/OfflineBehavior",
        lv: "OfflineBehavior",
        clicked: "",
        children: [],
      },
      // {
      //   name: "",
      //   path: "",
      //   lv: "",
      //   clicked: "",
      //   children: [],
      // },
    ],
  },
  {
    name: "360度顧客輪廓",
    img: iconNew360Gary,
    imgFocus: iconNew360,
    path: "/New360Page",
    lv: "New360Page",
    clicked: "New360",
    children: [],
  },
  {
    name: "顧客分析模組",
    img: iconCustomerAnalysisGray,
    imgFocus: iconCustomerAnalysis,
    path: "/CustomerAnalysis",
    lv: "CustomerAnalysis",
    clicked: "CustomerAnalyze",
    children: [],
  },
  {
    name: "建案模組",
    img: iconBuildingCaseGray,
    imgFocus: iconBuildingCase,
    path: "/BuildingCase",
    lv: "CustomerAnalysis",
    clicked: "Building",
    children: [],
  },
  {
    name: "顧客貼標分群",
    img: iconCustomerLabelGray,
    imgFocus: iconCustomerLabel,
    path: "",
    lv: "CustomerLabel",
    clicked: "tagGroup",
    children: [
      {
        name: "價值分群",
        path: "/RFMpage",
        lv: "RFMpage",
        clicked: "",
        children: [],
      },
      {
        name: "個性化分群",
        path: "/RFMCustomPage",
        lv: "RFMCustomPage",
        clicked: "",
        children: [],
      },
      {
        name: "分群管理與報表",
        path: "/LabelManage",
        lv: "LabelManage",
        clicked: "",
        children: [],
      },
      {
        name: "標籤群組管理",
        path: "/LabelManagement",
        lv: "LabelManagement",
        clicked: "",
        children: [],
      },
    ],
  },
  {
    name: "AI數據分析",
    img: AIDataAnalyzeGray,
    imgFocus: AIDataAnalyze,
    path: "",
    lv: "AIDataAnalyze",
    clicked: "aiGroup",
    children: [
      {
        name: "AI名單推薦",
        path: "/RankRecommend",
        lv: "AIListRecommend",
        clicked: "",
        children: [],
      },
      {
        name: "行銷AI機器人",
        path: "/AIRobotIan",
        lv: "SaleAIRobot",
        clicked: "",
        children: [],
      },
    ],
  },
]

const navReMa = [
  {
    name: "ReMA行銷自動化",
    img: iconRema,
    imgFocus: iconRema,
    path: "",
    lv: "Rema",
    clicked: "isActiveRema",
    children: [
      {
        name: "Email",
        path: "",
        lv: "RemaEmail",
        clicked: "remaEmail",
        children: [
          {
            name: "Email模板",
            path: "/ProductTextTemplate",
            lv: "ProductTextTemplate",
            clicked: "",
            children: [],
          },
          {
            name: "Coupon投放",
            path: "/EmailCoupon",
            lv: "EmailCoupon",
            clicked: "",
            children: [],
          },
          {
            name: "排程",
            path: "/MarketingSchedule",
            lv: "MarketingSchedule",
            clicked: "",
            children: [],
          },
        ],
      },
      // 新的測試NavBarUI
      // {
      //   name: "Email(測試)",
      //   path: "",
      //   lv: "RemaEmail_New",
      //   clicked: "remaEmail-test",
      //   children: [
      //     {
      //       name: "Email模板(測試)",
      //       path: "/ProductTextTemplate_New",
      //       lv: "ProductTextTemplate_New",
      //       clicked: "",
      //       children: [],
      //     },
      //     {
      //       name: "排程(測試)",
      //       path: "/MarketingSchedule_New",
      //       lv: "MarketingSchedule_New",
      //       clicked: "",
      //       children: [],
      //     },
      //   ],
      // },

      {
        name: "SMS",
        path: "",
        lv: "RemaSMS",
        clicked: "remaSms",
        children: [
          {
            name: "簡訊模板",
            path: "/ProductSmsTemplate",
            lv: "ProductSmsTemplate",
            clicked: "",
            children: [],
          },
          {
            name: "排程",
            path: "/SmsSchedule",
            lv: "SmsSchedule",
            clicked: "",
            children: [],
          },
        ],
      },
      {
        name: "Meta Ads",
        path: "",
        lv: "RemaFB",
        clicked: "remaFacebook",
        children: [
          {
            name: "受眾包預覽",
            path: "/FacebookAdsPackage",
            lv: "FacebookAdsPackage",
            clicked: "",
            children: [],
          },
          {
            name: "點擊追蹤功能",
            path: "/FbShortUrl",
            lv: "FbShortUrl",
            clicked: "",
            children: [],
          },
        ],
      },
      {
        name: "Line",
        path: "",
        lv: "RemaLine",
        clicked: "isActiveRemaLine",
        children: [
          {
            name: "受眾包預覽",
            path: "/LineAdsPackage",
            lv: "LineAdsPackage",
            clicked: "",
            children: [],
          },
          {
            name: "漸強受眾包",
            path: "/CresLabLineAdsPackage",
            lv: "CresLabLineAdsPackage",
            clicked: "",
            children: [],
          },
          {
            name: "點擊追蹤功能",
            path: "/LineShortUrl",
            lv: "LineShortUrl",
            clicked: "",
            children: [],
          },
        ],
      },
      {
        name: "Google Ads",
        path: "",
        lv: "RemaGoogle",
        clicked: "isActiveRemaGoogle",
        children: [
          {
            name: "受眾包預覽",
            path: "/GoogleAdsPackage",
            lv: "GoogleAdsPackage",
            clicked: "",
            children: [],
          },
          {
            name: "點擊追蹤功能",
            path: "/GoogleShortUrl",
            lv: "GoogleShortUrl",
            clicked: "",
            children: [],
          },
        ],
      },
      {
        name: "自動化劇本",
        path: "",
        lv: "AutomationScript",
        clicked: "remaScript",
        children: [
          {
            name: "劇本一覽表",
            path: "/ScriptTable",
            lv: "ScriptTable",
            clicked: "",
            children: [],
          },
        ],
      },
    ],
  },
  {
    name: "行銷成效",
    img: iconSalePerformanceGray,
    imgFocus: iconSalePerformance,
    path: "",
    lv: "MarketingPerformance",
    clicked: "isActivePerformance",
    children: [
      {
        name: "活動行事曆",
        path: "/FullCalendar",
        lv: "FullCalendar",
        clicked: "",
        children: [],
      },
      {
        name: "整體行銷成效",
        path: "/AllAdvEffect",
        lv: "AllAdvEffect",
        clicked: "",
        children: [],
      },
      {
        name: "Email",
        path: "",
        lv: "EmailPerformance",
        clicked: "email",
        children: [
          {
            name: "投放紀錄一覽表",
            path: "/EmailMarketingTable",
            lv: "EmailMarketingTable",
            clicked: "",
            children: [],
          },
          {
            name: "整體投放趨勢",
            path: "/LatestResult",
            lv: "LatestResult",
            clicked: "",
            children: [],
          },
        ],
      },
      {
        name: "SMS",
        path: "",
        lv: "SMSperformance",
        clicked: "sms",
        children: [
          {
            name: "投放紀錄一覽表",
            path: "/SmsMarketingTable",
            lv: "SmsMarketingTable",
            clicked: "",
            children: [],
          },
          {
            name: "整體投放趨勢",
            path: "/SmsLatestResult",
            lv: "SmsLatestResult",
            clicked: "",
            children: [],
          },
        ],
      },
      {
        name: "Meta ads",
        path: "",
        lv: "FBperformance",
        clicked: "fb",
        children: [
          {
            name: "投放紀錄一覽表",
            path: "/FbadsTable",
            lv: "FbadsTable",
            clicked: "",
            children: [],
          },
          {
            name: "趨勢比較分析",
            path: "/FbTrendAnal",
            lv: "FbTrendAnal",
            clicked: "",
            children: [],
          },
          {
            name: "活動主題比較",
            path: "/FbThemeAnal",
            lv: "FbThemeAnal",
            clicked: "",
            children: [],
          },
        ],
      },
      {
        name: "Google Ads",
        path: "",
        lv: "Googleperformance",
        clicked: "google",
        children: [
          {
            name: "投放紀錄一覽表",
            path: "/GoogleadsTable",
            lv: "GoogleadsTable",
            clicked: "",
            children: [],
          },
        ],
      },
    ],
  },
  {
    name: "平台管理",
    img: iconPlatMemberGray,
    imgFocus: iconPlatMember,
    path: "",
    lv: "PlatformManage",
    clicked: "memberManage",
    children: [
      {
        name: "報表資料管理",
        path: "/reportExport",
        lv: "reportExport",
        clicked: "",
        children: [],
      },
      {
        name: "行銷名單管理",
        path: "/MemberUploadDel",
        lv: "MemberUploadDel",
        clicked: "",
        children: [],
      },
      {
        name: "行銷投放紀錄",
        path: "/MemberData",
        lv: "MemberData",
        clicked: "",
        children: [],
      },
    ],
  },
]

export { navOverview, navReMa}