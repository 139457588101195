<template>
  <div class="leftNav" v-if="isMainSideBar">
    <Navbar :navData="navOverview" />
    <hr />
    <Navbar :navData="navReMa" />
    <li style="" class="logo-wrapper">
      <div>
        <img src="../assets/leftnav-created.png" alt="logo" />
      </div>
    </li>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import Navbar from "../components/LeftNav/Navbar.vue";
import { navOverview, navReMa } from "../config/navSetting.js";

const route = useRoute();

/**
 * 會顯示LeftNav.vue元件的分頁
 */
const isMainSideBar = computed(() => {
  return (
    route.path !== "/AccountSetting" &&
    route.path !== "/accountSetting" &&
    route.path !== "/TermPage" &&
    route.path !== "/termPage" &&
    route.path !== "/PrivacyPage" &&
    route.path !== "/privacyPage" &&
    route.path !== "/AuthorizationManage" &&
    route.path !== "/authorizationManage" &&
    route.path !== "/UploadFile"
  );
});
</script>

<style scoped lang="scss">
li {
  cursor: pointer;
}

img {
  position: relative;
  top: -2px;
}

.leftNav {
  position: relative;
  background-color: #eaf3f4;
  min-width: 215px;
  min-height: calc(89vh);
  padding-top: 30px;
}

.logo-wrapper {
  margin-top: 8rem;
  > div {
    position: absolute;
    bottom: 10px;
    margin-left: 1.5rem;
    img {
      width: 100%;
    }
  }
}

hr {
  width: 100%;
  margin-top: 0px;
  opacity: 1;
  height: 0;
  border: 0;
  border-bottom: 1px solid #63a7c8;
}
.nav-main {
  color: #8a8a8a;
  cursor: pointer;
  .nav-second {
    cursor: pointer;
  }
  .router-link-exact-active {
    color: white;
    background-color: #63a7c8;
  }
}
</style>
