<template>
  <div class="wrapper-main">
    <aside v-for="main in newNavData" :key="main">
      <!-- 只有一層 -->
      <div v-if="main?.path !== ''">
        <router-link
          class="nav-main"
          :class="isActive(main?.path) ? 'active' : ''"
          style="padding-left: 0px; margin-left: -5px"
          :to="main?.path"
          active-class="active"
          data-gtm="left-nav"
        >
          <img
            :src="main?.imgFocus"
            class="imgIcon"
            style="margin-left: 5px"
            alt="已開啟"
            v-show="clickedButton[main?.clicked]"
          />
          <img
            :src="main?.img"
            alt="未開啟"
            class="imgIcon"
            style="margin-left: 5px"
            v-show="!clickedButton[main?.clicked]"
          />
          <span :class="clickedButton[main?.clicked] ? 'White' : ''">{{
            main?.name
          }}</span>
        </router-link>
      </div>

      <!-- 不只一層 -->
      <div v-if="main?.path === ''">
        <ul
          class="nav-main"
          data-gtm="left-nav"
          @click="toggleButton(main?.clicked)"
        >
          <img
            :src="main?.imgFocus"
            class="imgIcon"
            alt="已開啟"
            v-show="clickedButton[main?.clicked]"
          />
          <img
            :src="main?.img"
            class="imgIcon"
            alt="未開啟"
            v-show="!clickedButton[main?.clicked]"
          />
          <span
            :class="clickedButton[main?.clicked] ? 'DarkBlue' : ''"
            :style="main?.name === 'ReMA行銷自動化' ? 'margin-left:.4rem' : ''"
            >{{ main?.name }}</span
          >
          <img
            :src="blueDownArrow"
            alt="down-arrow"
            class="blue-arrow-icon"
            v-show="clickedButton[main?.clicked]"
          />
          <img
            :src="rightArrow"
            alt="right-arrow"
            class="grey-arrow-icon"
            v-show="!clickedButton[main?.clicked]"
          />
        </ul>
        <!-- 第二層nav -->
        <div
          class="wrapper-second"
          v-if="main.children.length !== 0 && clickedButton[main?.clicked]"
        >
          <section
            v-for="navSecond in main.children"
            :key="navSecond"
            class="nav-second"
          >
            <!-- 只有兩層 -->
            <router-link
              :to="navSecond?.path"
              :class="isActive(navSecond?.path) ? 'active' : ''"
              active-class="active"
              data-gtm="left-nav"
              v-if="navSecond?.path !== ''"
            >
              {{ navSecond?.name }}
            </router-link>
            <!-- 不只兩層 -->
            <div
              class="cursor-pointer Gray8A"
              data-gtm="left-nav"
              v-if="navSecond?.path === ''"
            >
              <div
                :class="
                  clickedButton[navSecond?.clicked] ? 'DarkBlue' : 'Gray8A'
                "
                style="padding-left: 32px; height: 40px; line-height: 40px"
                @click="toggleButton(navSecond?.clicked)"
              >
                {{ navSecond?.name }}
                <img
                  :src="rightArrow"
                  alt="right-arrow"
                  class="grey-arrow-icon"
                  v-show="!clickedButton[navSecond?.clicked]"
                />
                <img
                  :src="blueDownArrow"
                  alt="down-arrow"
                  class="blue-arrow-icon"
                  v-show="clickedButton[navSecond?.clicked]"
                />
              </div>

              <!-- 第三層nav -->
              <div
                class="wrapper-third"
                v-if="
                  navSecond?.children.length !== 0 &&
                  clickedButton[navSecond?.clicked]
                "
              >
                <section
                  v-for="navThird in navSecond.children"
                  :key="navThird"
                  class="nav-third"
                >
                  <router-link
                    :to="navThird?.path"
                    :class="isActive(navThird?.path) ? 'active' : ''"
                    active-class="active"
                    data-gtm="left-nav"
                    v-if="navThird?.path !== ''"
                  >
                    {{ navThird?.name }}
                  </router-link>
                  <div v-else>
                    <!-- 不只三層 -->
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </aside>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineExpose,
  computed,
  onMounted,
  watch,
} from "vue";
import { storeToRefs } from "pinia";
import { useAccountInfo } from "../../store/useAccountInfo.js";
import { useRoute } from "vue-router";
import developMode from "../../config/developMode";

//picture
import rightArrow from "../../assets/greyArrow.svg";
import blueDownArrow from "../../assets/blueArrow.svg";

//icon
import iconOperationalDisplayGray from "../../assets/trendGray.png";
import iconOperationalDisplay from "../../assets/trend.png";
import iconWebsiteBehaviorGray from "../../assets/website-anal-grey.svg";
import iconWebsiteBehavior from "../../assets/website-anal-blue.svg";
import iconCustomerBuyGray from "../../assets/analyzeGray.png";
import iconCustomerBuy from "../../assets/analyze.png";
import iconNew360Gary from "../../assets/New360Gray.svg";
import iconNew360 from "../../assets/New360White.svg";
import iconCustomerAnalysisGray from "../../assets/Customer-analysis-gray.svg";
import iconCustomerAnalysis from "../../assets/Customer-analysis.svg";
import iconBuildingCaseGray from "../../assets/Building-case-gray.svg";
import iconBuildingCase from "../../assets/Building-case.svg";
import iconCustomerLabelGray from "../../assets/customTagGroupGray.svg";
import iconCustomerLabel from "../../assets/customTagGroupBlue.svg";
import iconRema from "../../assets/rema.svg";
import iconSalePerformanceGray from "../../assets/effectGray.svg";
import iconSalePerformance from "../../assets/effectBlue.svg";
import iconPlatMemberGray from "../../assets/platMemberGray.svg";
import iconPlatMember from "../../assets/platMemberBlue.svg";

const props = defineProps({
  //要產生導覽列的資料
  navData: {
    type: Array,
    default: () => [],
  },
});

//控制那些分頁要顯示。0:導覽列不顯示該分頁且不顯示分頁內容；1、2、3：導覽列顯示該分頁且顯示分頁內容
const navLv = ref({});
const route = useRoute();
const newNavData = ref([]); //排除沒有權限的資料

/* 左側導覽列，哪個大分類要被點開 */
const clickedButton = ref({
  // login: false,
  // behavior: false,
  // retailGoods: false,
  // Website: false,
  // New360: false,
  // labelGroup: false,
  // customerProfile: false,
  // tagGroup: false,
  // aiGroup: false,
  // memberManage: false,
  // memberLimit: false,
  // auto: false,
  // advEffect: false,
  // Building: false,
  // remaSms: false,
  // remaFacebook: false,
  // remaLine: false,
  // isActiveRemaGoogle: false,
  // remaScript: false,
  // email: false,
});

function getLv() {
  const { lv } = storeToRefs(useAccountInfo());
  navLv.value = lv.value;
  // console.log("lv分頁權限", navLv.value);
  /* 0：導覽列不顯示其分頁，並無法看到該分頁內容 */
  /* 1：檢視者、2：編輯者、3：執行者、4：管理員*/
}
//打開/摺疊導覽頁
function toggleButton(params) {
  clickedButton.value[params] = !clickedButton.value[params];
}

/* 有些按鈕即使到其他頁面，還是要呈現藍底白字的hover樣式 */
function isActive(pathName) {
  /* 未定義 */
  const beActive = pathName; //要被hover的分頁路徑
  if (!pathName) {
    return false;
  }
  if (
    route.path === "/SmsResultPerformance" &&
    beActive === "/SmsMarketingTable"
  ) {
    return true;
  }
  if (
    route.path === "/ResultPerformance" &&
    beActive === "/EmailMarketingTable"
  ) {
    return true;
  }
  if (route.path === "/FbadsTableByAdv" && beActive === "/FbadsTable") {
    return true;
  }
  if (route.path === "/MemberLimit" && beActive === "/MemberUploadDel") {
    return true;
  } else {
    return false;
  }
}

/* 排除沒有權限的頁面，不渲染 */
function filterLv(data) {
  if (!data || data.length === 0 || Object.keys(navLv.value).length === 0) {
    return;
  }

  function doFilter(nav) {
    return nav.filter((item) => {
      const hasLv = navLv.value[item.lv] !== 0; //判斷此按鈕有權限顯示
      if (hasLv && item.children && item.children.length > 0) {
        // 如果還有子層，則判斷子層的按鈕是否有權限
        item.children = doFilter(item.children);
      }
      return hasLv;
    });
  }
  newNavData.value = doFilter(data);
}

watch(
  route,
  async function (route) {
    await getLv();
    filterLv(props.navData);

    /* reset所有可展開的按鈕 */
    for (let key in clickedButton.value) {
      clickedButton.value[key] = false;
    }
    /**
     * 判斷哪一種大分類要被展開
     * @description clickedButton.value[分頁的lv名稱] = true時，該分業會被active
     */
    switch (route.path) {
      case "/Login":
      case "/ForgotPassword":
      case "/ResetPassword":
        clickedButton.value.login = true;
        break;
      case "/OperationalOverview":
      case "/MembershipSales":
      case "/StoreChannelSales":
      case "/IndicatorCompare":
        clickedButton.value.behavior = true;
        break;
      case "/CommoditySales":
      case "/CommodityAdvanceAnalysis":
        clickedButton.value.behavior = true;
        clickedButton.value.retailGoods = true;
        break;
      case "/WebsiteAnalysis":
      case "/WebsiteAdvance":
      case "/clickForceTag":
        clickedButton.value.Website = true;
        break;
      case "/ValueGrouping":
      case "/CustomLabel":
      case "/OfflineBehavior":
        clickedButton.value.labelGroup = true;
        break;
      case "/New360Page":
        clickedButton.value.New360 = true;
        break;
      case "/CustomerAnalysis":
        clickedButton.value.CustomerAnalyze = true;
        break;
      case "/BuildingCase":
        clickedButton.value.Building = true;
        break;
      case "/RFMpage":
      case "/RFMCustomPage":
      case "/LabelManage":
      case "/LabelManagement":
        clickedButton.value.tagGroup = true;
        break;
      case "/RankRecommend":
      case "/AIRobotIan":
        clickedButton.value.aiGroup = true;
        break;

      case "/TriggerScript":
      case "/MarketingScript":
      case "/AfterTriggerScript":
        clickedButton.value.isActiveRema = true;
        break;
      case "/ProductTextTemplate":
      case "/EmailCoupon":
      case "/MarketingSchedule":
      case "/EmailSetting":
        clickedButton.value.isActiveRema = true;
        clickedButton.value.remaEmail = true;
        break;
      case "/SmsSchedule":
      case "/ProductSmsTemplate":
      case "/SmsSetting":
        clickedButton.value.isActiveRema = true;
        clickedButton.value.remaSms = true;
        break;
      case "/FacebookAdsPackage":
      case "/FbShortUrl":
        clickedButton.value.isActiveRema = true;
        clickedButton.value.remaFacebook = true;
        break;
      case "/LineAdsPackage":
      case "/LineShortUrl":
      case "/CresLabLineAdsPackage":
        clickedButton.value.isActiveRema = true;
        clickedButton.value.isActiveRemaLine = true;
        break;
      case "/GoogleAdsPackage":
      case "/GoogleShortUrl":
        clickedButton.value.isActiveRema = true;
        clickedButton.value.isActiveRemaGoogle = true;
        break;
      case "/ScriptTable":
        clickedButton.value.isActiveRema = true;
        clickedButton.value.remaScript = true;
        break;
      case "/FullCalendar":
      case "/AllAdvEffect":
        clickedButton.value.advEffect = true;
        break;
      case "/EmailMarketingTable":
      case "/ResultPerformance":
      case "/EventPerformance":
      case "/LatestResult":
        clickedButton.value.advEffect = true;
        clickedButton.value.email = true;
        break;
      case "/SmsMarketingTable":
      case "/SmsLatestResult":
      case "/SmsResultPerformance":
      case "/SmsEventPerformance":
        clickedButton.value.advEffect = true;
        clickedButton.value.sms = true;
        break;
      case "/FbadsTable":
      case "/FbadsTableByAdv":
      case "/FbMarketingTable":
      case "/FbMarketingTrend":
      case "/FbTrendAnal":
      case "/FbThemeAnal":
        clickedButton.value.advEffect = true;
        clickedButton.value.fb = true;
        break;
      case "/GoogleadsTable":
      case "/GoogleMarketingTrend":
        clickedButton.value.advEffect = true;
        clickedButton.value.google = true;
        break;
      case "/MemberData":
      case "/MemberUploadDel":
      case "/reportExport":
      case "/MemberLimit":
        clickedButton.value.memberManage = true;
        break;
      // Rema 測試功能開啟nav bar------------------
      case "/MarketingSchedule_New":
        clickedButton.value.isActiveRema = true;
        clickedButton.value["remaEmail-test"] = true;
        break;
      case "/ProductTextTemplate_New":
        clickedButton.value.isActiveRema = true;
        clickedButton.value["remaEmail-test"] = true;
        break;
      case "/EmailSetting_New":
        clickedButton.value.isActiveRema = true;
        clickedButton.value["remaEmail-test"] = true;
        break;
      default:
        break;
    }
  },
  { immediate: true }
);

onMounted(() => {});
</script>

<style scoped lang="scss">
a {
  display: block;
  color: #8a8a8a;
  text-decoration: none;
  border-radius: 8px;
  /* margin: 10px; */
  height: 40px;
  line-height: 40px;
  padding-left: 35px;
}
/*觸及樣式*/
a:not(.active):hover {
  background-color: white;
}
.wrapper-main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  aside {
    margin: auto 10px auto 16px;
    .imgIcon {
      position: relative;
      top: -2px;
    }
    .grey-arrow-icon {
      width: 8px;
      height: 12px;
      position: relative;
      top: -2px;
      left: 4px;
    }
    .blue-arrow-icon {
      width: 12px;
      height: 8px;
      position: relative;
      top: -2px;
      left: 2px;
    }
    .router-link-exact-active {
      color: white;
      background-color: #63a7c8;
    }
    .active {
      color: white;
      background-color: #63a7c8;
    }
    .nav-main {
      height: 40px;
      line-height: 40px;
      color: #8a8a8a;
      cursor: pointer;
      > span {
        margin-left: 0.8rem;
      }
    }
    .wrapper-second {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .nav-second {
        cursor: pointer;
        > a {
          padding-left: 32px;
        }
      }
      .nav-second:first-child {
        margin-top: 6px;
      }
    }

    .wrapper-third {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .nav-third {
        > a {
          padding-left: 50px;
        }
      }
      .nav-third:first-child {
        margin-top: 6px;
      }
    }
  }
  aside:last-child {
    margin-bottom: 18px;
  }
}
</style>
